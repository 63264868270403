
import { mapBeteiligungToBeteiligungInput } from '@/application/GraphQLUtils'
import { rulesRequired } from '@/application/Validators'
import BeteiligungenSelectionComponent from '@/components/beteiligungen/BeteiligungenSelectionComponent.vue'
import BetraegeComponent from '@/components/beteiligungen/BetraegeComponent.vue'
import ErrorBanner, { ErrorData } from '@/components/ErrorBanner.vue'
import FileSelectComponent from '@/components/files/FileSelectComponent.vue'
import DateField from '@/components/forms/DateField.vue'
import EditDialogAction from '@/components/forms/EditDialogAction.vue'
import EditDialogCard from '@/components/forms/EditDialogCard.vue'
import {
  Beteiligung,
  BeteiligungFieldsFragment,
  CreateTopfDocument,
  CreateTopfMutation,
  CreateTopfMutationVariables,
  DeleteTopfDocument,
  DeleteTopfMutation,
  DeleteTopfMutationVariables,
  KontoFieldsFragment,
  KontosByTypeQuery,
  KontosByTypeQueryVariables,
  KontoTyp,
  TopfOeffnenDocument,
  TopfOeffnenMutation,
  TopfOeffnenMutationVariables,
  TopfQueryVariables,
  TopfSchliessenDocument,
  TopfSchliessenMutation,
  TopfSchliessenMutationVariables,
  TopfStatus,
  UpdateBeteiligteQuery,
  UpdateBeteiligteQueryVariables,
  UpdateTopfDocument,
  UpdateTopfMutation,
  UpdateTopfMutationVariables,
  useKontosByTypeQuery,
  useTopfQuery,
  useUpdateBeteiligteQuery
} from '@/generated/graphql'
import { ApolloError } from 'apollo-client'
import { DocumentNode } from 'graphql'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'

@Component({
  components: {
    ErrorBanner,
    DateField,
    EditDialogAction,
    EditDialogCard,
    BetraegeComponent,
    FileSelectComponent,
    BeteiligungenSelectionComponent
  },
  apollo: {
    topf: useTopfQuery<TopfCreate>({
      variables(): TopfQueryVariables {
        if (this.topfId) {
          return {
            id: this.topfId
          }
        } else {
          throw new Error('no topfId')
        }
      },
      update: data => ({
        id: data.topf.id,
        bezeichnung: data.topf.bezeichnung,
        verantwortlicher: data.topf.verantwortlicher,
        beschreibung: data.topf.beschreibung,
        antragUrl: data.topf.antragUrl,
        eroeffnungsbetrag: data.topf.kontostand.budget,
        status: data.topf.status,
        kontostand: {
          verbraucht: data.topf.kontostand.verbraucht,
          budget: data.topf.kontostand.budget,
          verfuegbar: data.topf.kontostand.verfuegbar
        }
      } as TopfFormData),
      skip(): boolean {
        return !this.topfId // Only do this query, if we have an ID
      }
    }),
    moeglicheKostentraeger: useKontosByTypeQuery<TopfCreate>({
      variables(): KontosByTypeQueryVariables {
        return {
          type: KontoTyp.Guthabenkonto
        }
      },
      update: (data: KontosByTypeQuery): KontoFieldsFragment[] => data.konten
    }),
    kostentraeger: useUpdateBeteiligteQuery<TopfCreate>({
      variables(): UpdateBeteiligteQueryVariables {
        return {
          input: {
            beteiligte: ['ssterfolg2024'],
            baseline: {
              totalBetrag: '0',
              beteiligungen: []
            }
          }
        }
      },
      update: (data: UpdateBeteiligteQuery): BeteiligungFieldsFragment[] => data.updateBeteiligte
    })
  }
})
export default class TopfCreate extends Vue {

  loading = false

  @Prop({
    required: false,
    default: null
  })
  readonly topfId!: string | null

  topf: TopfFormData = {
    id: '',
    bezeichnung: '',
    verantwortlicher: '',
    beschreibung: '',
    antragUrl: '',
    eroeffnungsbetrag: '0',
    status: TopfStatus.Offen,
    kontostand: {
      verbraucht: '0',
      verfuegbar: '0',
      budget: '0'
    }
  }

  kostentraeger: Array<Beteiligung> = []

  moeglicheKostentraeger: KontoFieldsFragment[] = []

  // Form Validation
  formValid = false
  readonly rulesRequired: InputValidationRules = rulesRequired

  errorData: ErrorData | null = null

  get isClosed() {
    return this.topfId !== null && this.topf.status === TopfStatus.Geschlossen
  }

  get isCloseable(): boolean {
    return parseFloat(this.topf.kontostand.verfuegbar) > 0
  }

  get kontostandAufNull(): boolean {
    return parseFloat(this.topf.kontostand.verfuegbar) === 0
  }

  get hasBuchungen(): boolean {
    return parseInt(this.topf.kontostand.verbraucht, 10) !== 0
  }

  close(): Promise<void> {
    const id = this.topfId || '-1'
    return this.mutate(TopfSchliessenDocument, { id })
  }

  upsert(): Promise<void> {
    if (this.topfId) {
      return this.update(this.topfId)
    } else {
      return this.create()
    }
  }

  open(): Promise<void> {
    const id = this.topfId || '-1'
    return this.mutate(TopfOeffnenDocument, { id })
  }

  deleteItem(): Promise<void> {
    const id = this.topfId || '-1'
    return this.mutate(DeleteTopfDocument, { id })
  }

  update(id: string): Promise<void> {
    return this.mutate(UpdateTopfDocument, {
      updateInfo: {
        id,
        bezeichnung: this.topf.bezeichnung,
        verantwortlicher: this.topf.verantwortlicher,
        beschreibung: this.topf.beschreibung,
        antragUrl: this.topf.antragUrl
      }
    })
  }

  create(): Promise<void> {
    return this.mutate(CreateTopfDocument, {
      updateInfo: {
        bezeichnung: this.topf.bezeichnung,
        verantwortlicher: this.topf.verantwortlicher,
        beschreibung: this.topf.beschreibung,
        eroeffnungsbetrag: this.topf.eroeffnungsbetrag,
        antragUrl: this.topf.antragUrl,
        kostentraeger: this.kostentraeger.map(mapBeteiligungToBeteiligungInput)
      }
    })
  }

  private async mutate(mutation: DocumentNode, variables:
      CreateTopfMutationVariables | TopfSchliessenMutationVariables |
      UpdateTopfMutationVariables | TopfOeffnenMutationVariables |
      DeleteTopfMutationVariables): Promise<void> {
    if (!this.topf) {
      return
    }
    try {
      this.loading = true
      await this.$apollo.mutate<CreateTopfMutation | TopfSchliessenMutation | TopfOeffnenMutation | UpdateTopfMutation | DeleteTopfMutation
          ,
          CreateTopfMutationVariables | TopfSchliessenMutationVariables |
          UpdateTopfMutationVariables | TopfOeffnenMutationVariables |
          DeleteTopfMutationVariables>({
        mutation,
        variables
      })
      this.$emit('completed')
    } catch (e) {
      if (e instanceof ApolloError) {
        const graphQLError = e.graphQLErrors[0]
        this.errorData = {
          code: graphQLError?.extensions?.code,
          message: graphQLError?.message
        }
      } else {
        throw e
      }
    } finally {
      this.loading = false
    }

  }

}

type TopfFormData = {
  id: string,
  bezeichnung: string,
  kontostand: {
    verbraucht: string,
    verfuegbar: string,
    budget: string
  },
  eroeffnungsbetrag: string
  verantwortlicher: string
  beschreibung: string
  antragUrl: string
  status: TopfStatus
}
